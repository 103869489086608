
/**
 * @template T
 * @param {(...)=>T} tryFn 
 * @returns {T|{error}}
 */
function trycatch(tryFn,keeperr) {
	try {
		return tryFn()
	} catch (error) {
		if(keeperr)return { error }
	}
}
/**
 * 
 * @template T
 * @typedef {T|Y[]|() => Y} Y
 */

/**
 * @template T
 * @param {Y<T>} a 
 * @returns {T}
 */
const objArrFun = a => {
	if(typeof a === "function") a = a.call(this) // please only if there are no params
	if(typeof a === "object" && a.length !== null && a.length !== undefined) {
		a = Array.from(a,objArrFun)
		if(!a.length) return null
		if(a.length === 1) return a[0]
	}
	return a;
};

function getElement(query,parent=document,create=true) {
	console.debug("getElement",{query,parent});
	let result = trycatch(() => parent.querySelectorAll(query));
	if (create&&(!result || !result.length)) return createElement(query);
	return objArrFun(result)
}
/**
 * 
 * @param {string|Node} element 
 * @returns {Node|NodeList|null}
 */
function createElement(element) {
	console.debug("createElement",element);
	const useFragment = element.length > 1000; // Adjust the threshold based on your needs
	let container;
	if (useFragment) {
		const fragment = document.createDocumentFragment();
		container = document.createElement("div");
		fragment.appendChild(container);
	} else {
		container = document.createElement("div");
	}

	container.innerHTML = element;
	const result = container.children;

	return objArrFun(result);
}



function applyAttributes(element, attributes={}) {
	if (typeof element === "string") element = getElement(element)
	console.debug("applyAttributes",{element, attributes});
	if (element instanceof Element) {
		if (attributes && typeof attributes === "object")
			Object.entries(attributes || {}).forEach(([key, value]) => {
				if (key.startsWith('on') && typeof value === 'function') {
					element.addEventListener(key.slice(2).toLowerCase(), value);
				} else if (element instanceof HTMLElement) {
					element.setAttribute(key, value);
				} else {
					element[key] = value
				}
			});
	}else {
        console.warn("Invalid element type in applyAttributes:", element);
        // Handle gracefully, e.g., log a warning
    }
	return element
}

function queryChildren(element, queries, base) {
	if (typeof element === "string") element = getElement(element)
	base = typeof base === "undefined"?element:base;
	console.debug("queryChildren",{element, queries, base});
	if (element instanceof Node)
		return Object.entries(queries).reduce((result, [key, query]) => { 
			result[key] = getElement(query,element,false);
			return result
		}, base || {});
	else console.warn("Invalid element type in queryChildren:", element);
	return base ||element|| {};
}

/**
 * 
 * @param {Node} element 
 * @param {Node} parent 
 * @param {Boolean} force
 * @param {"append"|"prepend"} place 
 * @returns 
 */
function setParent(element,parent,force=true,place="append") {
	if (typeof element === "string") element = getElement(element)
	if (typeof parent === "string") parent = getElement(parent)
	if(!(element instanceof Node && parent instanceof Node)) return element;
	if(force || !element.parentElement)
		parent[place](element)
	return element;
}



function createImage(src, attributes) {
	return applyAttributes(new Image(), { src, ...attributes });
}

function createP(text) {
	const p = createElement("<p></p>")
	p.textContent = text;
	return p;
}

function createLink(href,attributes, ...child) {
	const a = applyAttributes(`<a></a>`, {alt:" ",...attributes, href })
	a.append(...child)
	return a;
}

const createRow = obj=> Object.entries(obj).map(([key,value])=>{
	let cell = document.createElement("td");
	cell.dataset.propName = key;
	cell.innerText = value;
	return cell;
})


function createTable(arr) {
	const table = document.createElement("table")
	const th = document.createElement("thead")
	th.append(...createRow(Object.keys(arr[0])))
	table.appendChild(th)

	table.append(...arr.map(item=>{
		const tr = document.createElement("tr")
		tr.append(...createRow(Object.values(item)))
		return tr
	}))
	return table
}



createTable([
	{
		name:"face",
		age:3
	},
	{
		name:"face",
		age:3
	},
	{
		name:"face",
		age:3
	},
	{
		name:"face",
		age:3
	},
	{
		name:"face",
		age:3
	},
])